.sociocon {
    color: #FFFFFF;
    width: 100%;
    font-family: 'Roboto';
    font-weight: 300;
    background-color: #55A98A;
    padding: 8px 16px;;
    border-radius: 16px;
font-size: 16px;
    margin: 12px 0px;

    display: flex;
    cursor: pointer;
    &-icon {
        margin-right: 16px;
    }

    &-container {
        flex-direction: row;
        &-icon {

        font-size: 24px;
        }

        &-text {
            display:flex;
            align-items: center;
            justify-content: center;
        }
    }
}