.frontpage {
    &-main {
        display:flex;
        flex-direction: row;
        flex-wrap: nowrap;

        @include max-xl {
            flex-direction: column-reverse;
        }

        &-left {
            flex-basis: 50%;
            @include max-xl {
                min-height: 100vh;
            }
            padding: 24px;


            display:flex;
            min-height: 100vh;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            &-sociocons {
                margin-top: 24px;
            }
        }

        &-right {
            flex-basis: 50%;
            position: relative;

            display:flex;

            @include max-xl {
                align-items: center;
                justify-content: center;
               
            }
            @include max-xl {
                min-height: 100vh;
            }

            &-image-right {
                position: absolute;
                right:64px;
            }

            &-woffy {
                z-index: 2;
                position: absolute;
                right:0px;
                top: -32px;
                @include max-xl {
                    // right: calc(50vw - 256px)
                    right: unset;
                    top: unset;
                }

                @include max-md {
                    // right: calc(50vw - 236px)
            
                }
            }

            &-background {
                z-index: 1;
                position: absolute;
                right:0px;
                max-height: calc(100vh - 24px) !important;

                @include max-xl {
                    right: 0;
                    min-width: 100vw;
                    max-height: 100vh !important;
                    max-width: unset !important;
                    object-fit: cover;
                }

                @include max-md {
                    max-width: unset !important;
                }
                
            }

        }
    }
    
    &-sideimage {
       //  max-width: 50vw;
        max-height: 100vh;


        @include max-xl {
            max-width: 100vw;
            max-height: 100vh;
    
        }
    }

    @keyframes swipedown {
        0% {margin-top: 0px;}
        20% {margin-top: 10px;}
        100% {margin-top: 20px;}
    }

    @keyframes hide {
        from {opacity: 1}
        to {opacity: 0}
    }

    &-popup-info {
        position: relative;
        top: calc(50vh - 82px);
        z-index: 4;

        background: rgba(64, 64, 64, 0.8);
        padding: 0px 8px;
        border-radius: 64px;
        color: #ffffff;
        font-size: 48px;
        &-animated {
            animation: swipedown 1s linear 1s infinite alternate;
        }

        @include min-xl {
            display:none;
        }

        &-hidden {
            display:none;
        }
    }

    &-heading {
        color: #55A98A;
        text-align: center;
        font-weight: 800;
        font-family: 'Assistant';
        font-size: 64px;
    }

    &-subheading {
        color: #55A98A;
        text-align: center;
        font-weight: 400;
        font-family: 'Maven Pro';
        font-size: 24px;
    }
}