
@mixin min-sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
	}
 }
 
 @mixin min-md {
	@media (min-width: #{$screen-md-min}) {
		@content;
	}
 }

 @mixin min-lg {
	@media (min-width: #{$screen-lg-min}) {
		@content;
	}
 }
 
 @mixin min-xl {
	@media (min-width: #{$screen-xl-min}) {
		@content;
	}
 }

 @mixin min-xxl {
	@media (min-width: #{$screen-xxl-min}) {
		@content;
	}
 }

 @mixin max-sm {
	@media (max-width: #{$screen-sm-min}) {
		@content;
	}
 }
 
 @mixin max-md {
	@media (max-width: #{$screen-md-min}) {
		@content;
	}
 }

 @mixin max-lg {
	@media (max-width: #{$screen-lg-min}) {
		@content;
	}
 }
 
 @mixin max-xl {
	@media (max-width: #{$screen-xl-min}) {
		@content;
	}
 }
 @mixin max-xxl {
	@media (max-width: #{$screen-xxl-min}) {
		@content;
	}
 }